'use client'

import { Toaster } from "@/components/ui/sonner";
import { useSession } from "next-auth/react"
import { useTheme } from "next-themes";
import { Sarabun } from "next/font/google";
import { usePathname } from "next/navigation"

const thsarabun = Sarabun({ subsets: ['thai'], weight: ['400', '500', '600', '700'], display: 'swap' });

export default function Template({ children }: { children: React.ReactNode }) {
    const pathname = usePathname();
    const { resolvedTheme } = useTheme();
    const { data: session, status } = useSession(
        { required: pathname !== '/auth/signin' }
    );

    if (status === 'loading') return null;
    if (session === undefined) return null;
    
    return (
        <>
            <meta name='color-scheme' content={resolvedTheme} />
            <Toaster position="bottom-center" className={"text-base text-center " + thsarabun.className} />
            {children}
        </>
    )
}